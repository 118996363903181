import axios from "axios";

const apiUrl = process.env.VUE_APP_REIVEW_API_URL ?? "https://feedback.bnb-platform.com/api";

const http = axios.create({
  baseURL: apiUrl,
  timeout: 1000,
});

export default http;
